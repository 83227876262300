import React, {useState, useEffect} from 'react';
import styles from './blockchainproducts.module.scss'
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { EffectCoverflow, Pagination } from "swiper";
import 'swiper/swiper.scss';
import 'swiper/modules/effect-coverflow/effect-coverflow.scss'
import Product from "../Product/Product";
import {IsMobileDevice} from "../../utils/functions";


export function BlockchainProducts({images}) {

    useEffect(() => {
        setSlides(IsMobileDevice() ? 1.5 : 4)
    }, []);

    const [slides, setSlides] = useState(4);


    const params = {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 30,
        coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 2,
            slideShadows: true
        },
    };

    return (
        <div className={styles.container}>
            {images &&
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={slides}
                loop={true}
                coverflowEffect={{
                    rotate: 3,
                    stretch: 0,
                    depth: 100,
                    modifier: 3,
                    slideShadows: false,
                }}
                pagination={{ clickable: true, type: "progressbar", el: ".swiper-pagination" }}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
            >
                <SwiperSlide className={styles.item} >
                    <Product
                        image={images["tokens"] && images["tokens"]}
                        subtitle={""}
                        title={"Tokenomics"}
                        paragraph={
                            "Te ayudamos a fondear tu proyecto mediante la emisión de Tokens. La acompañamos de un sitio web donde podes explicar tu propósito."
                        }
                        link={"https://webitesa.web.app/"}
                    />
                </SwiperSlide>
                <SwiperSlide className={styles.item} >
                    <Product
                        image={images["nfts"] && images["nfts"]}
                        subtitle={""}
                        title={"NFT Marketplace"}
                        paragraph={
                            "Creamos un markeplace propio para que tus clientes minten, compren y vendan tus coleccionables digitales. Armamos las piezas segun las rarezas que nos especifiques."
                        }
                        link={"https://webitesa.web.app/"}
                    />
                </SwiperSlide>
                <SwiperSlide className={styles.item} >
                    <Product
                        image={images["producto2"] && images["producto2"]}
                        subtitle={""}
                        title={"Crypto Exchange"}
                        paragraph={
                            "Un MVP para tu propio exchange de criptomonedas donde los usuarios van a poder intercambiar los tokens que tu elijas"
                        }
                        link={"https://webitesa.web.app/"}
                    />
                </SwiperSlide>
                <SwiperSlide className={styles.item} >
                    <Product
                        image={images["producto2"] && images["producto2"]}
                        subtitle={""}
                        title={"Bot Automation"}
                        paragraph={
                            "Mediante el uso de machine learning podemos armar bots que automaticen el proceso de compra venta de criptomonedas segun las reglas que nos indiques. "
                        }
                        link={"https://webitesa.web.app/"}
                    />
                </SwiperSlide>



            </Swiper>
            }

        </div>
    )
}
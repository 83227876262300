import React, { Fragment, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout";
import Last from "../components/Last/Last";
import { IsMobileDevice, CleanArrayImages } from "../utils/functions";
import { useRecoilState, useRecoilValue } from "recoil";
import { isMobileSelector } from "../recoil/selectors";
import { isMobile, Mode } from "../recoil/atoms";
// import BlockchainBanner from "../components/BlockchainBanner/BlockchainBanner";
import AboutBlockchain from "../components/AboutBlockchain/AboutBlockchain";
import ContactBlockchain from "../components/ContactBlockchain/ContactBlockchain";
import Products from "../components/Products/Products";
import AOS from "aos";
import { configTube } from "../lib/tube/config";
import loadable from "@loadable/component";
const BlockchainBanner = loadable(() => import("../components/BlockchainBanner/BlockchainBanner"));
const Blockchain = ({ data }) => {
  const [images, setImages] = useState({});
  const [, setIsMobile] = useRecoilState(isMobile);
  const [, setMode] = useRecoilState(Mode);
  const mobile = useRecoilValue(isMobileSelector);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    setIsMobile(IsMobileDevice());
    if (localStorage.theme) {
      setMode(localStorage.theme);
    } else {
      setMode("dark"); 
    }
    setImages(CleanArrayImages(data));
  }, [data, setMode, setIsMobile]);

  return (
    <Fragment>
      <Helmet>
        <script src="../../tube/jsband-min.js" />
        <script src="../../tube/tube-min.js" />
      </Helmet>
      <SEO
        title="Innovation Hub"
        description="Somos una startup con base en Buenos Aires que provee equipos on demand de alta calidad para trabajar en proyectos de innovación y tecnología, con foco en diseño UX, desarrollo de software e ingeniería."
      />
      <Layout>
        <BlockchainBanner />
        <AboutBlockchain
          brands={Object.keys(images).length > 0 && images.Customers}
          images={Object.keys(images).length > 0 && images.About}
          productImages={Object.keys(images).length > 0 && images.Products}
        />
        <Last
          images={
            Object.keys(images).length > 0 && [
              images.Messi644,
              images.Blasfemia,
              images.Escappe,
              images.JuntosPorSudamerica,
              images.Lollapalooza,
              images.Patagonia,
            ]
          }
        />
        <ContactBlockchain />
      </Layout>
    </Fragment>
  );
};

export default Blockchain;

export const data = graphql`
  query ImgBlockchain {
    About: allFile(filter: { relativeDirectory: { eq: "About" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Customers: allFile(filter: { relativeDirectory: { eq: "Customers" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Methodology: allFile(filter: { relativeDirectory: { eq: "Methodology" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
    }
    Services: allFile(filter: { relativeDirectory: { eq: "Services" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Services: allFile(filter: { relativeDirectory: { eq: "Services" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Last: allFile(filter: { relativeDirectory: { eq: "Last" } }) {
      edges {
        node {
          name
          base
          publicURL
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Products: allFile(filter: { relativeDirectory: { eq: "Products" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Messi644: allFile(
      filter: { relativeDirectory: { eq: "Projects/Budweiser/Messi644" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Blasfemia: allFile(
      filter: { relativeDirectory: { eq: "Projects/Blasfemia" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Escappe: allFile(
      filter: { relativeDirectory: { eq: "Projects/Escappe" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    JuntosPorSudamerica: allFile(
      filter: {
        relativeDirectory: { eq: "Projects/Conmebol/JuntosPorSudamerica" }
      }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Lollapalooza: allFile(
      filter: { relativeDirectory: { eq: "Projects/Budweiser/Lollapalooza" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Patagonia: allFile(
      filter: { relativeDirectory: { eq: "Projects/Patagonia" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

import React, { useState } from "react";
import styles from "./ContactBlockchain.module.scss";
import { Col, Button, Form } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { ModeSelector } from "../../recoil/selectors";
import firebase from "gatsby-plugin-firebase";

const ContactBlockchain = () => {
  const mode = useRecoilValue(ModeSelector);
  const [interests, setInterests] = useState([]);
  const [status, setStatus] = useState("ENVIAR");
  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInterests = (interest) => {
    if (interests.includes(interest)) {
      const newInterests = interests.filter((int) => int !== interest);
      setInterests(newInterests);
    } else {
      setInterests((prev) => [...prev, interest]);
    }
  };

  const handleChange = (field, value) => {
    setContactInfo((prev) => ({ ...prev, [field]: value }));
  };

  const submitForm = (e) => {
    e.preventDefault();

    let formSubmission = {
      interests,
      ...contactInfo,
      type: "blockchain",
      date: new Date().toISOString(),
    };
    firebase.firestore().collection("contactosBlockchain").add(formSubmission);
    fetch("https://us-central1-webitesa.cloudfunctions.net/contact", {
      method: "POST",
      body: JSON.stringify({ formSubmission }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setStatus("ENVIADO");
        setTimeout(() => {
          setStatus("ENVIAR");
        }, 3000);
        // }else{
        //   console.error("Error enviando los datos. \n", response);
        // }
      })
      .catch((err) => {
        console.error("Error", err);
      });
    setStatus("ENVIANDO...");
  };

  return (
    <div className={styles.ContactFormContainer}>
      <div className={styles.fullcont}>
        <div
          data-aos="fade-right"
          data-aos-delay="250"
          className={`${styles.textbar}`}
        >
          <p id="contact" className={`${styles.itesa} animated fadeIn`}>
            ITESA
          </p>
          <p
            style={{ color: mode === "dark" || !mode ? "white" : "black" }}
            className={`${styles.title} fadeIn-left`}
          >
            Contacto
            <span>.</span>
          </p>
          <p
            style={{ color: mode === "dark" || !mode ? "white" : "black" }}
            className={`${styles.text} fadeIn-left`}
          >
            Contactate con el equipo para poder recibir una cotización o hablar
            con especialistas para ayudarte a llevar a cabo tu idea 🚀
          </p>
        </div>
        <div className={styles.formcontainer}>
          <Form
            data-aos="fade-right"
            data-aos-delay="450"
            className={styles.form}
          >
            <Form.Row className={styles.formrow}>
              <Col md="4">
                <Form.Control
                  style={{
                    color: mode === "dark" || !mode ? "white" : "black",
                  }}
                  className={`${styles.input} ${styles.nameinput}`}
                  type="text"
                  value={contactInfo.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  placeholder="Tu Nombre"
                />
              </Col>
              <Col md="8">
                <Form.Control
                  style={{
                    color: mode === "dark" || !mode ? "white" : "black",
                  }}
                  className={styles.input}
                  type="email"
                  value={contactInfo.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  placeholder="Tu Email"
                />
              </Col>
            </Form.Row>
            <Form.Row className={styles.formrow}>
              <Col md="12">
                <Form.Control
                  style={{
                    color: mode === "dark" || !mode ? "white" : "black",
                  }}
                  className={styles.input}
                  type="text"
                  as="textarea"
                  rows={4}
                  placeholder="Mensaje"
                  value={contactInfo.message}
                  onChange={(e) => handleChange("message", e.target.value)}
                />
              </Col>
            </Form.Row>

            <small style={{ color: "gray" }}>
              Seleccioná tu área de interés
            </small>
            <div className={styles.toggleContainer}>
              <div
                role="button"
                className={
                  interests.includes("Criptomonedas")
                    ? styles.toggleButtonAct
                    : styles.toggleButton
                }
                style={{ color: mode === "dark" || !mode ? "white" : "black" }}
                onClick={() => handleInterests("Criptomonedas")}
                onKeyPress={() => handleInterests("Criptomonedas")}
              >
                Criptomonedas
              </div>
              <div
                role="button"
                className={
                  interests.includes("Wallet propia")
                    ? styles.toggleButtonAct
                    : styles.toggleButton
                }
                style={{ color: mode === "dark" || !mode ? "white" : "black" }}
                onClick={() => handleInterests("Wallet propia")}
                onKeyPress={() => handleInterests("Wallet propia")}
              >
                Wallet propia
              </div>
              <div
                role="button"
                className={
                  interests.includes("NFT Marketplace")
                    ? styles.toggleButtonAct
                    : styles.toggleButton
                }
                style={{ color: mode === "dark" || !mode ? "white" : "black" }}
                onClick={() => handleInterests("NFT Marketplace")}
                onKeyPress={() => handleInterests("NFT Marketplace")}
              >
                NFT Marketplace
              </div>
              <div
                role="button"
                className={
                  interests.includes("Dapps")
                    ? styles.toggleButtonAct
                    : styles.toggleButton
                }
                style={{ color: mode === "dark" || !mode ? "white" : "black" }}
                onClick={() => handleInterests("Dapps")}
                onKeyPress={() => handleInterests("Dapps")}
              >
                Dapps
              </div>
              <div
                role="button"
                className={
                  interests.includes("Bots")
                    ? styles.toggleButtonAct
                    : styles.toggleButton
                }
                style={{ color: mode === "dark" || !mode ? "white" : "black" }}
                onClick={() => handleInterests("Bots")}
                onKeyPress={() => handleInterests("Bots")}
              >
                Bots
              </div>
            </div>
            <Form.Row className={styles.formrow}>
              <Col md="4">
                <Button
                  type="submit"
                  onClick={submitForm}
                  className={styles.submit}
                >
                  {status}
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ContactBlockchain;

export const configTube = (size) => {
  return {
    //Main settings
    TUBE_SIZE: size,
    TUBE_WIDTH: 40.441,
    TUBE_DISTANCE: 1000,
    PERSPECTIVE_DISTORTION: 1,
    TUBE_TORSION: 0.461,
    TUBE_STRETCH: 229.412,
    ROTATION_SPEED: 5,
    DOT_COLOR: "rgb(158, 56, 252)",
    DOT_SIZE: 0,
    DETALIZATION: 500,
    MESH_DISTRIBUTION: 0,
    MESH_RATIO: 14,
    OFFSET_X: 0, //offset in pixels
    OFFSET_Y: 0, //offset in pixels
    PERCENTAGE_OFFSET_X: 0, //offset in percentages
    PERCENTAGE_OFFSET_Y: 0, //offset in percentages

    //Responsivity settings
    AUTO_SCALE: true,
    VIEWPORT_WIDTH: 1000,
    VIEWPORT_HEIGHT: 1000,

    //Mouse settings
    MOUSE_DISTANCE_MIN: 20,
    MOUSE_DISTANCE_MAX: 400,
    MOUSE_SENSITIVITY: 1,
    INERTIAL_TIME: 2,

    //Main morphing settings
    USE_MAIN_MORPHING: true,
    MAIN_MORPHING_AUTOPLAY: true,
    MAIN_MORPHING_DURATION: 1.5,
    MAIN_MORPHING_DELAY: 2,
    MAIN_MORPHING_TRANSITION_TYPE: "cubic",
    MAIN_MORPHING_STAGES: [
      {
        TUBE_SIZE: 389.348,
        TUBE_WIDTH: 80.441,
        TUBE_DISTANCE: 1000,
        PERSPECTIVE_DISTORTION: 1,
        TUBE_TORSION: 0.461,
        TUBE_STRETCH: 229.412,
        ROTATION_SPEED: 5,
        DOT_COLOR: "rgb(25, 25, 25)",
        DOT_SIZE: 2,
        DETALIZATION: 500,
        MESH_DISTRIBUTION: 0,
        MESH_RATIO: 14,
      },
      {
        TUBE_SIZE: 389.348,
        TUBE_WIDTH: 80.441,
        TUBE_DISTANCE: 1000,
        PERSPECTIVE_DISTORTION: 1,
        TUBE_TORSION: 0.294,
        TUBE_STRETCH: 94.118,
        ROTATION_SPEED: 5,
        DOT_COLOR: "rgb(242, 242, 242)",
        DOT_SIZE: 2,
        DETALIZATION: 500,
        MESH_DISTRIBUTION: 0,
        MESH_RATIO: 14,
      },
      {
        TUBE_SIZE: 423.593,
        TUBE_WIDTH: 55.441,
        TUBE_DISTANCE: 1000,
        PERSPECTIVE_DISTORTION: 1,
        TUBE_TORSION: 0.941,
        TUBE_STRETCH: 317.647,
        ROTATION_SPEED: 5,
        DOT_COLOR: "rgb(14, 14, 14)",
        DOT_SIZE: 2,
        DETALIZATION: 500,
        MESH_DISTRIBUTION: 0,
        MESH_RATIO: 14,
      },
      {
        TUBE_SIZE: 437.98,
        TUBE_WIDTH: 49.706,
        TUBE_DISTANCE: 1000,
        PERSPECTIVE_DISTORTION: 1,
        TUBE_TORSION: 0.373,
        TUBE_STRETCH: 135.294,
        ROTATION_SPEED: 5,
        DOT_COLOR: "rgb(158, 56, 252)",
        DOT_SIZE: 2,
        DETALIZATION: 500,
        MESH_DISTRIBUTION: 0,
        MESH_RATIO: 14,
      },
      {
        TUBE_SIZE: 396.686,
        TUBE_WIDTH: 74.706,
        TUBE_DISTANCE: 1000,
        PERSPECTIVE_DISTORTION: 1,
        TUBE_TORSION: 0.373,
        TUBE_STRETCH: 211.765,
        ROTATION_SPEED: 5,
        DOT_COLOR: "rgb(242, 242, 242)",
        DOT_SIZE: 2,
        DETALIZATION: 500,
        MESH_DISTRIBUTION: 2,
        MESH_RATIO: 14,
      },
      {
        TUBE_SIZE: 69.49,
        TUBE_WIDTH: 39.559,
        TUBE_DISTANCE: 1000,
        PERSPECTIVE_DISTORTION: 1,
        TUBE_TORSION: -0.745,
        TUBE_STRETCH: 241.176,
        ROTATION_SPEED: 5,
        DOT_COLOR: "rgb(14, 14, 14)",
        DOT_SIZE: 2,
        DETALIZATION: 500,
        MESH_DISTRIBUTION: -2,
        MESH_RATIO: 14,
      },
    ],

    //Surface distortion settings
    SURFACE_INITIAL_DISTORTION: {
      wave1: { amplitude: 0, frequency: 3, phase: 0 },
      wave2: { amplitude: 0, frequency: 3, phase: 1.5 },
      wave3: { amplitude: 25.49, frequency: 1, phase: 4.651 },
    },
    USE_SURFACE_MORPHING: true,
    USE_SURFACE_MOTION: true,
    USE_SURFACE_SWING: true,

    //Surface morphing settings
    SURFACE_MORPHING_AUTOPLAY: true,
    SURFACE_MORPHING_DURATION: 1.5,
    SURFACE_MORPHING_DELAY: 2,
    SURFACE_MORPHING_TRANSITION_TYPE: "cubic",
    SURFACE_MORPHING_STAGES: [
      {
        wave1: { amplitude: 0, frequency: 3, phase: 0 },
        wave2: { amplitude: 0, frequency: 3, phase: 1.5 },
        wave3: { amplitude: 25.49, frequency: 1, phase: 4.651 },
      },
      {
        wave1: { amplitude: 41.176, frequency: 3, phase: 0 },
        wave2: { amplitude: 0, frequency: 3, phase: 1.5 },
        wave3: { amplitude: 25.49, frequency: 1, phase: 0.893 },
      },
      {
        wave1: { amplitude: 59.804, frequency: 3, phase: 5.513 },
        wave2: { amplitude: 0, frequency: 3, phase: 1.5 },
        wave3: { amplitude: 3.922, frequency: 1, phase: 4.312 },
      },
      {
        wave1: { amplitude: 7.843, frequency: 3, phase: 0 },
        wave2: { amplitude: 0, frequency: 3, phase: 1.5 },
        wave3: { amplitude: 7.843, frequency: 1, phase: 0.246 },
      },
      {
        wave1: { amplitude: 61.765, frequency: 3, phase: 0 },
        wave2: { amplitude: 0, frequency: 3, phase: 1.5 },
        wave3: { amplitude: 22.549, frequency: 1, phase: 2.279 },
      },
      {
        wave1: { amplitude: 14.706, frequency: 3, phase: 4.22 },
        wave2: { amplitude: 0, frequency: 3, phase: 1.5 },
        wave3: { amplitude: 14.706, frequency: 1, phase: 2.279 },
      },
    ],

    //Surface motion settings
    WAVE_1_MOTION_SPEED: -2,
    //WAVE_2_MOTION_SPEED: 3,
    WAVE_3_MOTION_SPEED: 2,

    //Surface swing settings
    WAVE_1_SWING_SETTINGS: {
      amplitude: { amplitude: 15, frequency: 0.5, phase: 0, type: "sine" },
      //frequency: {amplitude: 0, frequency: 0, phase: 0, type: "sine"},
      //phase: {amplitude: 0, frequency: 0, phase: 0, type: "sine"}
    },

    WAVE_2_SWING_SETTINGS: {
      amplitude: { amplitude: 2, frequency: 2, phase: 0, type: "sine" }, //,
      //frequency: {amplitude: 0, frequency: 0, phase: 0, type: "sine"},
      //phase: {amplitude: 0, frequency: 0, phase: 0, type: "sine"}
    },

    WAVE_3_SWING_SETTINGS: {
      //amplitude: {amplitude: 5, frequency: 1, phase: 0, type: "sine"},
      //frequency: {amplitude: 0, frequency: 0, phase: 0, type: "sine"},
      //phase: {amplitude: 2, frequency: 0.2, phase: 2.1, type: "sine"}
    },
  };
};
